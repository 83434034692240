import React from 'react'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { random } from 'lodash';
import { useDashboardActivityProgressQuery } from 'hooks/useFdbDashboard';

const PieChart = (param) => {
  const { data: activityProgress } = useDashboardActivityProgressQuery([
    "activity-progress",
    {
      // month: month + 1,
      year: new Date().getFullYear(),
    },
  ]);

  const options = {
    chart: {
      backgroundColor: "#EEFFF4",
      type: "pie",
    },
    title: {
      text: "Persentase",
    },
    plotOptions: {
        pie: {
            shadow: false,
            dataLabels: {
                color:'white',
                distance: -35,
                formatter: function () {
                    if(this.y!=0)  return Math.round(this.y);

                }
            }
        },
    },
    tooltip: {
        formatter: function() {
            return '<b>'+ this.point.name +'</b>: '+ this.y;
        }
    },
    series: [{
        name: 'Browsers',
        // data: [["Firefox",6],["MSIE",4],["Chrome",7]],
        data: activityProgress?.data?.result ? (param.type == 'plan' ? activityProgress?.data?.result?.plan.map(e => {
          return {
            y: e.total,
            name: e.service_type,
            color: `rgb(0,${Math.floor(Math.random() * (150 - 100 + 1)) + 120},0)`
          }
        }) : activityProgress?.data?.result?.realization.map(e => {
          return {
            y: e.total,
            name: e.service_type,
            color: `rgb(0,${Math.floor(Math.random() * (150 - 100 + 1)) + 120},0)`
          }
        }) ) : [],
        innerSize: '60%',
        showInLegend:true,
    }],
    credits: {
      enabled: false,
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default PieChart