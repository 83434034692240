import config from "app/config";
import axiosInstance from "app/interceptors";

export const getDashboard = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/dashboard/${params.id}`
  );
};

export const getDashboardResume = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/dashboard/resume`
  );
};

export const getDashboardMonitoring = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/dashboard/monitoring`
  );
};

export const getDashboardActivityProgress = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/dashboard/activity-progress`
  );
};

export const getDashboardFinancialStatus = (params) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/dashboard/financial-status`,
    { params }
  );
};