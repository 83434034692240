import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useDashboardMonitoringQuery } from "hooks/useFdbDashboard";
import React from "react";

const BarChart = () => {

  const { data: monitoring } = useDashboardMonitoringQuery([
    "monitoring",
    {
      // month: month + 1,
      year: new Date().getFullYear(),
    },
  ]);

  const options = {
    chart: {
      type: "column",
      backgroundColor: "#EEFFF4",
    },
    colors: ["#2EFA88", "#00BD52"],
    title: {
      text: "Total perencanaan & realisasi debitur",
      align: "left",
    },
    // subtitle: {
    //   text:
    //     'Source: <a target="_blank" ' +
    //     'href="https://www.indexmundi.com/agriculture/?commodity=corn">indexmundi</a>',
    //   align: "left",
    // },
    xAxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"],
      crosshair: true,
      accessibility: {
        description: "Countries",
      },
    },
    yAxis: {
      min: 0,
      // title: {
      //   text: "1000 metric tons (MT)",
      // },
    },
    tooltip: {
      valueSuffix: " (1000 MT)",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: monitoring?.data?.result ?? [],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default BarChart;
