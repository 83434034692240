import { Breadcrumbs, Input, Table, TitleText } from "components";
import moment from "moment";
import { useState } from "react";
import BarChart from "./components/chart/bar-chart";
import PieChart from "./components/chart/pie-chart";
import { useDashboardFinancialStatusQuery, useDashboardResumeQuery } from "hooks/useFdbDashboard";

const DahsboardFDB = () => {
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  // Get today's date for max attribute
  const maxDate = moment().format("YYYY-MM-DD");

  const handleChange = (event) => {
    setDate(event.target.value);
  };

  const { data: resume } = useDashboardResumeQuery([
    "resume",
    {
      // month: month + 1,
      year: new Date().getFullYear(),
    },
  ]);

  const { data: financialStatusUpper } = useDashboardFinancialStatusQuery([
    "financial-status",
    {
      type: 'upper',
      year: new Date().getFullYear(),
    },
  ]);

  const { data: financialStatusLower } = useDashboardFinancialStatusQuery([
    "financial-status",
    {
      type: 'lower',
      year: new Date().getFullYear(),
    },
  ]);

  // const dummyData = [
  //   {
  //     area: "Wilayah A",
  //     debtor_numbers: 100,
  //     nominal: "Rp. XXX,XXX,XXX",
  //   },
  //   {
  //     area: "Wilayah B",
  //     debtor_numbers: 100,
  //     nominal: "Rp. XXX,XXX,XXX",
  //   },
  //   {
  //     area: "Wilayah C",
  //     debtor_numbers: 100,
  //     nominal: "Rp. XXX,XXX,XXX",
  //   },
  //   {
  //     area: "Wilayah D",
  //     debtor_numbers: 100,
  //     nominal: "Rp. XXX,XXX,XXX",
  //   },
  //   {
  //     area: "Wilayah E",
  //     debtor_numbers: 100,
  //     nominal: "Rp. XXX,XXX,XXX",
  //   },
  // ];

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no = index + 1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "working_area_name",
      title: "Nama Wilayah",
      dataIndex: "working_area_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          Wilayah Kerja {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "total_debtor",
      title: "Jumlah Debitur",
      dataIndex: "total_debtor",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "total_application_fee",
      title: "Nominal",
      dataIndex: "total_application_fee",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-6">
      <Breadcrumbs items={[{ label: "Dashboard FDB" }]} />

      {/* Header */}
      <div className="flex items-center justify-between">
        <TitleText className="flex-1">Dashboard FDB</TitleText>
        <Input
          type="date"
          name="date"
          className="w-1/4"
          value={date}
          onChange={handleChange}
          max={maxDate}
        />
      </div>

      {/* Card */}
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col p-6 gap-4 bg-white rounded-xl border border-gray-200 shadow">
          <span className="text-xs font-medium text-gray-700">
            Total Debitur
          </span>
          <span className="text-4xl font-semibold">{resume?.data?.result?.debtor ?? "0"}</span>
        </div>
        <div className="flex flex-col p-6 gap-4 bg-white rounded-xl border border-gray-200 shadow">
          <span className="text-xs font-medium text-gray-700">
            Total Kelompok
          </span>
          <span className="text-4xl font-semibold">{resume?.data?.result?.group ?? "0"}</span>
        </div>
      </div>

      {/* <div className="grid grid-cols-4 gap-6">
        <div className="flex flex-col p-6 gap-2 bg-white rounded-xl border border-gray-200 shadow">
          <span className="text-xs font-medium text-gray-700">
            Dana yg dianggarkan
          </span>
          <span className="text-lg font-semibold">Rp. XXX,XXX,XXX,XXX</span>
        </div>
        <div className="flex flex-col p-6 gap-2 bg-white rounded-xl border border-gray-200 shadow">
          <span className="text-xs font-medium text-gray-700">
            Dana Tersalurkan
          </span>
          <span className="text-lg font-semibold">Rp. XXX,XXX,XXX,XXX</span>
        </div>
        <div className="flex flex-col p-6 gap-2 bg-white rounded-xl border border-gray-200 shadow">
          <span className="text-xs font-medium text-gray-700">
            Dana Dikembalikan
          </span>
          <span className="text-lg font-semibold">Rp. XXX,XXX,XXX,XXX</span>
        </div>
      </div> */}

      {/* Chart */}
      <div className="">
        <BarChart />
      </div>

      <div className="flex flex-col w-full gap-4">
        <span className="text-lg font-bold">Activity Progress</span>

        <div className="flex items-center justify-between gap-6">
          <div className="flex-1">
            <PieChart type={"plan"} />
          </div>
          <div className="flex-1">
            <PieChart type={"realization"} />
          </div>
        </div>
      </div>

      {/* Map */}
      <div className="flex flex-col gap-4">
        <span className="text-lg font-bold">
          Peta Lokasi Debitur & Kelompok FDB
        </span>

        {/* TODO: Research Map Library */}
      </div>

      {/* Table */}
      <span className="text-lg font-bold">Financial Status</span>
      <div className="grid grid-cols-2 gap-6">
        <div className="flex flex-col p-3 gap-2 bg-white rounded-xl border border-gray-200 shadow">
          <Table
            bordered
            stripped
            layout="fixed"
            className="mb-4"
            columns={tableColumns}
            dataSource={financialStatusUpper?.data?.result}
            // isLoading={isLoading}
            // onChangePage={onChangePage}
            // onChangeRowsPerPage={onChangeRowsPerPage}
            hidePagination
          />
        </div>
        <div className="flex flex-col p-3 gap-2 bg-white rounded-xl border border-gray-200 shadow">
          <Table
            bordered
            stripped
            layout="fixed"
            className="mb-4"
            columns={tableColumns}
            dataSource={financialStatusLower?.data?.result}
            // isLoading={isLoading}
            // onChangePage={onChangePage}
            // onChangeRowsPerPage={onChangeRowsPerPage}
            hidePagination
          />
        </div>
        {/* <div className="flex flex-col p-3 gap-2 bg-white rounded-xl border border-gray-200 shadow">
          <Table
            bordered
            stripped
            layout="fixed"
            className="mb-4"
            columns={tableColumns}
            dataSource={dummyData}
            // isLoading={isLoading}
            // onChangePage={onChangePage}
            // onChangeRowsPerPage={onChangeRowsPerPage}
            hidePagination
          />
        </div>
        <div className="flex flex-col p-3 gap-2 bg-white rounded-xl border border-gray-200 shadow">
          <Table
            bordered
            stripped
            layout="fixed"
            className="mb-4"
            columns={tableColumns}
            dataSource={dummyData}
            // isLoading={isLoading}
            // onChangePage={onChangePage}
            // onChangeRowsPerPage={onChangeRowsPerPage}
            hidePagination
          />
        </div> */}
      </div>
    </div>
  );
};
export default DahsboardFDB;
