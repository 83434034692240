import { useQuery } from "react-query";
import { DASHBOARD } from "services";


export const useDashboardResumeQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return DASHBOARD.getDashboardResume({ ...params }, signal);
    },
    ...options,
  });
};

export const useDashboardMonitoringQuery = (keys, options) => {
    return useQuery({
      queryKey: keys,
      queryFn: ({ signal, queryKey }) => {
        const params = queryKey[1];
        return DASHBOARD.getDashboardMonitoring({ ...params }, signal);
      },
      ...options,
    });
};

export const useDashboardActivityProgressQuery = (keys, options) => {
    return useQuery({
      queryKey: keys,
      queryFn: ({ signal, queryKey }) => {
        const params = queryKey[1];
        return DASHBOARD.getDashboardActivityProgress({ ...params }, signal);
      },
      ...options,
    });
};

export const useDashboardFinancialStatusQuery = (keys, options) => {
    return useQuery({
      queryKey: keys,
      queryFn: ({ signal, queryKey }) => {
        const params = queryKey[1];
        return DASHBOARD.getDashboardFinancialStatus({ ...params }, signal);
      },
      ...options,
    });
};